export default {
  name: "Toolbar",
  components: {},
  props: {},
  data() {
    return {
      brandingColor: "",
      brandingAccentColor: "",
      languages: [],
      orgSettings: null,
      utilSettings: null,
      images: {
        en: require("/src/assets/en.png"),
        el: require("/src/assets/el.png"),
        // ar: require("/src/assets/ar.png"),
      },
      showFullOrgName: true,
    };
  },
  created() {
    this.$root.$on("window_width_changed", this.handleWindowSizeChange);
    this.init();
  },
  methods: {
    init() {
      this.orgSettings = this.$store.getters.getOrgSettings;
      this.utilSettings = this.$store.getters.getUtilitySettings;

      //console.log("org settings", this.orgSettings.brandingAccentColor);

      this.languages = [...this.utilSettings.languages];

      if (this.languages == null || this.languages == [])
        this.languages.push({
          languageId: 1,
          language: "en",
          name: "English",
        });

      if (this.orgSettings && this.orgSettings.brandingAccentColor)
        this.brandingAccentColor =
          "color: " + this.orgSettings.brandingAccentColor + "!important;";

      if (this.orgSettings && this.orgSettings.brandingColor)
        this.brandingColor =
          "background-color: " + this.orgSettings.brandingColor + "!important;";
    },

    handleWindowSizeChange(width) {
      this.showFullOrgName = width >= 630;
    },
    getLangImage(langCode) {
      return this.images[langCode];
    },
    setLocale(lang) {
      this.$root.$emit("setLocale", lang);
    },
  },
};
