import axios from "axios";

export default {
  name: "PrivacyPolicy",
  props: {},
  data() {
    return {
      privacyText: null,
    };
  },
  computed: {
    title: function () {
      return this.privacyText &&
        this.privacyText.title &&
        this.privacyText.title.mainTitle
        ? this.privacyText.title.mainTitle
        : null;
    },
    subtitle: function () {
      return this.privacyText &&
        this.privacyText.title &&
        this.privacyText.title.subtitle
        ? this.privacyText.title.subtitle
        : null;
    },
    data: function () {
      return this.privacyText &&
        this.privacyText.paragraphs &&
        this.privacyText.paragraphs.length > 0
        ? this.privacyText.paragraphs
        : null;
    },
  },
  created() {
    this.$root.$on("locale_changed", this.getMessages);
    this.getMessages();
  },
  methods: {
    getMessages() {
      let param = { lang: this.$store.getters.getLocale, TextIds: ["52"] };

      axios
        .post("Utility/GetMessages", param)
        .then((response) => {
          if (
            response.status == 200 &&
            response.data &&
            response.data.length > 0 &&
            response.data[0] &&
            response.data[0].text
          ) {
            let temp = JSON.parse(response.data[0].text);
            for (let i = 0; i < temp.length; i++) temp[i]["id"] = i;

            this.privacyText = temp;
          } else this.$router.push({ name: "Support" });
        })
        .catch((err) => this.errorHandling(err));
    },
  },
};
